import React, { useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import SelectQueryFilter from "../SelectQueryFilter"
import SwitchQueryExistsFilter from "../SwitchQueryExistsFilter"
import BuildQueryContext from "../contexts/BuildQuery/context"
import FilterChips from "../FilterChips"
import { Box } from "@mui/system"

const FilterForm = () => {
  const { defaultBuilderId, isPending } = useContext(BuildQueryContext)
  const {
    allContentfulBuilder,
    allContentfulBuildType,
  } = useStaticQuery(graphql`
    query filterData {
      allContentfulBuilder {
        edges {
          node {
            id: contentful_id
            name
          }
        }
      }
      allContentfulBuildType {
        edges {
          node {
            id: contentful_id
            name: title
          }
        }
      }
    }
  `)

  const getFilterOptions = entryResults => {
    let options = {}
    entryResults.edges.forEach(entry => {
      options[entry.node.id] = entry.node.name
    })
    return options
  }

  const options = {
    allContentfulBuilder: getFilterOptions(allContentfulBuilder),
    allContentfulBuildType: getFilterOptions(allContentfulBuildType),
  }

  return (
    <Box sx={{ mb: 2 }}>
      <form>
        {!defaultBuilderId && (
          <SelectQueryFilter
            field="fields.builders.sys.id"
            label="Select Builder"
            options={options.allContentfulBuilder}
          />
        )}
        <SelectQueryFilter
          field="fields.types.sys.id"
          label="Select Build Type"
          options={options.allContentfulBuildType}
        />
        <SwitchQueryExistsFilter field="fields.set.sys.id" label="Sets only" />
      </form>
      {!isPending && <FilterChips filterData={options} />}
    </Box>
  )
}

export default FilterForm
