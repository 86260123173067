import React, { useContext } from "react"
import FilledInput from "@mui/material/FilledInput"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import BuildQueryContext from "../contexts/BuildQuery/context"

const SelectQueryFilter = ({ label, options, field }) => {
  const { addQuerySettings, removeQuerySetting, querySettings } = useContext(
    BuildQueryContext
  )

  const modifyQuery = id => {
    if (id === "_all") {
      removeQuerySetting(field)
    } else {
      let newQuerySettings = {}
      newQuerySettings[field] = id

      addQuerySettings(newQuerySettings)
    }
  }

  if (!querySettings) {
    return null
  }

  return (
    <FormControl
      variant="filled"
      color="secondary"
      sx={{ mr: 1, minWidth: 240 }}
    >
      <InputLabel htmlFor={field}>{label}</InputLabel>
      <Select
        value={options[querySettings[field]] ? querySettings[field] : "_all"}
        onChange={e => modifyQuery(e.target.value)}
        input={<FilledInput name={field} id={field} />}
      >
        <MenuItem value="_all">Any</MenuItem>
        {Object.keys(options).map(id => (
          <MenuItem value={id} key={id}>
            {options[id]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SelectQueryFilter
