import React, { useContext } from "react"
import BuildQueryContext from "../contexts/BuildQuery/context"
import { Box } from "@mui/system"

const ViewSummary = () => {
  const { totalItems, limit, querySettings } = useContext(BuildQueryContext)
  if (!totalItems) {
    return null
  }

  const { skip } = querySettings

  const resultTo = skip + limit > totalItems ? totalItems : skip + limit
  const Viewing =
    totalItems !== skip + 1
      ? () => (
          <span>
            <strong>{skip + 1}</strong> to <strong>{resultTo}</strong>
          </span>
        )
      : () => (
          <span>
            <strong>{totalItems}</strong>
          </span>
        )

  return (
    <Box sx={{ my: 2 }}>
      <em>
        Viewing <Viewing /> of <strong>{totalItems}</strong>
      </em>
    </Box>
  )
}

export default ViewSummary
