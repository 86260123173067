import React, { useContext } from "react"
import Switch from "@mui/material/Switch"
import BuildQueryContext from "../contexts/BuildQuery/context"
import FormControlLabel from "@mui/material/FormControlLabel"

const SwitchQueryExistsFilter = ({ field, label }) => {
  const { addQuerySettings, removeQuerySetting, querySettings } = useContext(
    BuildQueryContext
  )

  const modifyQuery = checked => {
    const filterProp = `${field}[exists]`
    if (!checked) {
      removeQuerySetting(filterProp)
    } else {
      const newQuerySettings = {
        [filterProp]: true,
      }

      addQuerySettings(newQuerySettings)
    }
  }

  return (
    <FormControlLabel
      control={
        <Switch
          color="primary"
          onChange={e => modifyQuery(e.target.checked)}
          checked={querySettings[field]}
        />
      }
      label={label}
    />
  )
}

export default SwitchQueryExistsFilter
