import React, { useState } from "react"
import Link from "../Link"
import { GatsbyImage } from "gatsby-plugin-image"
import DateLine from "../DateLine"
import { Chip } from "@mui/material"
import { Box } from "@mui/system"
import CardTitle from "../CardTitle"

const BuildListItemFull = ({ url, title, image, buildDate, set }) => {
  const [imageLoaded, setImageLoaded] = useState(false)

  return (
    <article>
      <Box>
        <Link to={url}>
          {image.fluid && (
            <GatsbyImage
              image={image.gatsbyImageData}
              alt={title}
              layout="constrained"
              objectFit="contain"
            />
          )}
          {image.basicSrc && (
            <>
              <img
                alt={title}
                src={image.basicSrc}
                onLoad={() => setImageLoaded(true)}
                style={
                  !imageLoaded ? { visibility: "hidden" } : { width: "100%" }
                }
              />
            </>
          )}
        </Link>
      </Box>
      <Box>
        {set && (
          <Chip
            label={set.fields.serialNumber}
            color="primary"
            sx={{ mr: 1, mb: 1 }}
          />
        )}
        <DateLine date={buildDate} format="MMMM YYYY" />
        <CardTitle>
          <Link to={url}>{title}</Link>
        </CardTitle>
      </Box>
    </article>
  )
}

export default BuildListItemFull
