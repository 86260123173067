import React, { useContext } from "react"
import BuildListItemFull from "../BuildListItemFull"
import BuildQueryContext from "../contexts/BuildQuery/context"
import LinearProgress from "@mui/material/LinearProgress"
import Grid from "@mui/material/Grid"
import { Box } from "@mui/material"
import CardTitle from "../CardTitle"

const BuildListResults = () => {
  const { builds, isPending } = useContext(BuildQueryContext)
  if (!isPending && builds.length === 0) {
    return (
      <Box>
        <CardTitle>No results to show.</CardTitle>
      </Box>
    )
  }
  return (
    <Grid container spacing={2} sx={{ mb: 2 }}>
      {isPending && <LinearProgress />}
      {!isPending && (
        <>
          {builds.map(build => (
            <Grid item xs={6} md={4}>
              <BuildListItemFull {...build} />
            </Grid>
          ))}
        </>
      )}
    </Grid>
  )
}

export default BuildListResults
