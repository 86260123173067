import React from "react"
import BuildListResults from "../BuildListResults"
import ViewSummary from "../ViewSummary"
import QueryPager from "../QueryPager"
import FilterForm from "../FilterForm"
import { Box } from "@mui/system"

const AdvancedBuildsListing = () => {
  return (
    <Box sx={{ my: 3 }}>
      <FilterForm />
      <BuildListResults />
      <ViewSummary />
      <QueryPager />
    </Box>
  )
}

export default AdvancedBuildsListing
