import React, { useState } from "react"
import BuildQueryContext from "./context"
import Url from "domurl"
import { navigate } from "gatsby"
import { useAsync } from "react-async"
import getBuilds from "../../../async/contentful.builds.js"

const WithBuildQuery = ({ limit, ajax, defaultBuilderId, children }) => {
  const getInitialStateFromUrl = () => {
    if (ajax || typeof window === `undefined`) {
      return {
        skip: 0,
      }
    }

    const url = new Url()
    const currentPage = parseInt(url.query.page) || 1
    const querySettings = {
      skip: currentPage === 1 ? 0 : (currentPage - 1) * limit,
    }

    const filterKeys = ["fields.types.sys.id", "fields.builders.sys.id"]

    Object.keys(url.query).forEach(parameterName => {
      if (filterKeys.indexOf(parameterName) !== -1) {
        querySettings[parameterName] = url.query[parameterName]
      }
    })

    return querySettings
  }

  const [querySettings, setQuerySettings] = useState(getInitialStateFromUrl)
  const { data, error, isPending } = useAsync({
    promiseFn: getBuilds,
    querySettings,
    limit,
    defaultBuilderId,
    watch: querySettings,
  })

  /**
   * Applies a set of new query settings and sets offset to 0.
   */
  const addQuerySettings = newQuerySettings => {
    setQuerySettings(prevQuerySettings => {
      const combinedQuerySettings = {
        ...prevQuerySettings,
        ...newQuerySettings,
        skip: 0,
      }

      if (!ajax) {
        const urlQueryParameters = {
          ...newQuerySettings,
          page: 1,
        }
        setUrlQueryParameters(urlQueryParameters)
      }

      return combinedQuerySettings
    })
  }

  /**
   * Removes a single query setting and sets offset to 0.
   */
  const removeQuerySetting = key => {
    setQuerySettings(prevQuerySettings => {
      const querySettings = {
        ...prevQuerySettings,
        skip: 0,
      }

      delete querySettings[key]
      if (!ajax) {
        removeUrlQueryParameter(key)
        setUrlQueryParameters({ page: 1 })
      }
      return querySettings
    })
  }

  const removeUrlQueryParameter = key => {
    // Since Gatsby 4, navigate has to be relative.
    const url = new Url("/builds", true)
    delete url.query[key]
    navigate(url.toString())
  }

  const setUrlQueryParameters = parameters => {
    const url = new Url("/builds", true)
    Object.assign(url.query, parameters)

    navigate(url.toString())
  }

  return (
    <BuildQueryContext.Provider
      value={{
        builds: data ? data.builds : null,
        totalItems: data ? data.totalItems : null,
        error,
        isPending,
        removeQuerySetting,
        addQuerySettings,
        setQuerySettings,
        setUrlQueryParameters,
        querySettings,
        defaultBuilderId,
        limit,
        ajax,
      }}
    >
      {children}
    </BuildQueryContext.Provider>
  )
}

export default WithBuildQuery
