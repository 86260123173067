import * as contentful from "contentful";

const getBuilds = async ({querySettings, limit, defaultBuilderId}) => {
  const client = contentful.createClient({
    space: `3zucgixqy9fs`,
    accessToken: `ac0ab28c99bc589de2fa9164da3080b72152b0fc5a6a34576534253a5e69d46b`,
  });

  const querySettingsPayload = {
    content_type: "build",
    limit: limit,
    order: "-fields.buildDate,-sys.createdAt",
  }

  if (defaultBuilderId) {
    querySettingsPayload["fields.builders.sys.id"] = defaultBuilderId
  }

  const result = await client
    .getEntries({
      ...querySettingsPayload,
      ...querySettings,
    });

  return {
    builds: result.items.map(entry => {
      if (entry.fields) {
        const values = entry.fields;
        return {
          url: `/build/${values.slug}`,
          title: values.title,
          buildDate: values.buildDate,
          key: entry.sys.id,
          set: values.set,
          image: {
            basicSrc: `${values.primaryMedia.fields.file.url}?w=450`,
          },
        };
      }
      return null;
    }),
    totalItems: result.total
  }
}

export default getBuilds;