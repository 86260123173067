import React, { useContext } from "react"
import ChevronRight from "@mui/icons-material/ChevronRight"
import ChevronLeft from "@mui/icons-material/ChevronLeft"
import Button from "@mui/material/Button"
import ButtonGroup from "@mui/material/ButtonGroup"
import BuildQueryContext from "../contexts/BuildQuery/context"

const QueryPager = () => {
  const {
    limit,
    ajax,
    querySettings,
    totalItems,
    setQuerySettings,
    setUrlQueryParameters,
  } = useContext(BuildQueryContext)

  const movePage = directive => {
    let skip = 0
    if (directive === "next") {
      skip = querySettings.skip + limit
    } else if (directive === "prev") {
      skip = querySettings.skip - limit
    } else if (directive === "first") {
      skip = 0
    }

    setQuerySettings(prevQuerySettings => {
      const newQuerySettings = {
        ...prevQuerySettings,
        skip,
      }
      return newQuerySettings
    })

    if (!ajax) {
      const page = Math.ceil(skip / limit) + 1
      setUrlQueryParameters({ page })
    }
  }

  const mayMovePage = directive => {
    const skip = querySettings.skip

    if (directive === "next") {
      return skip + limit < totalItems
    }
    if (directive === "prev") {
      return skip - limit >= 0
    }

    return true
  }

  return (
    <ButtonGroup aria-label="outlined primary button group" variant="contained">
      {mayMovePage("prev") && (
        <Button onClick={() => movePage("prev")}>
          <ChevronLeft /> Previous
        </Button>
      )}
      {mayMovePage("next") && (
        <Button onClick={() => movePage("next")}>
          Next <ChevronRight />
        </Button>
      )}
    </ButtonGroup>
  )
}

export default QueryPager
