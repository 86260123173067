import React, { useContext } from "react"
import BuildQueryContext from "../contexts/BuildQuery/context"
import Chip from "@mui/material/Chip"

const FilterChips = ({ filterData }) => {
  // const {querySettings, filterData, removeQuerySetting} = props
  const { querySettings, removeQuerySetting } = useContext(BuildQueryContext)
  const handleDelete = (filterId, filterValue) => {
    return removeQuerySetting(filterId)
  }

  const contentTypeToFilterField = {
    allContentfulBuilder: "fields.builders.sys.id",
    allContentfulBuildType: "fields.types.sys.id",
  }

  // If a filter is found in query settings, render it.
  return (
    <>
      {Object.keys(contentTypeToFilterField).map(contentType => {
        const filterId = contentTypeToFilterField[contentType]
        const filterValue = querySettings[filterId]
        if (!querySettings[filterId] || !filterData[contentType][filterValue]) {
          return null
        }

        return (
          <Chip
            label={filterData[contentType][filterValue]}
            onDelete={() => handleDelete(filterId, filterValue)}
            key={filterId}
            sx={{ my: 1, mr: 1 }}
          />
        )
      })}
    </>
  )
}

export default FilterChips
